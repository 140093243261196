import React from "react";
import "./Footer.css"
import {
    Image,
    Button,
    Stack,
    Modal
} from "react-bootstrap";
import { IoIosMail } from "react-icons/io";
import { FaPhone, FaYoutube, FaTwitter } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { useState } from 'react';
import images from "../Pages/img";
const Footer = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className="container-fluid nav_bg" style={{ backgroundColor: '#003a48', color: 'aliceblue' }}>
                <footer className="w-100 bg-light  ">
                    <Stack direction='horizontal' style={{ backgroundColor: '#003a48' }}>
                        <div className='mx-2 py-2'>
                            <h4 className="font-weigh-light">Contact Us</h4>
                            <Stack gap={3}>
                                <a href="tel:+91 78993 95226"><Button><FaPhone size="20px" /> +91 78993 95226</Button></a>
                                <a href="mailto:hallilabs@gmail.com"><Button><IoIosMail size="20px" /> E-Mail to halliLabs</Button></a>
                                <p><CiLocationOn size="20px" /> #46/1, Ragihalli village and post Jigani Hobli, Anekal Taluk, Bangalore Urban - 83</p>
                            </Stack>
                        </div>
                        <div className='mx-auto'>
                            <h4 className="font-weigh-light">Follow Us</h4>
                            <Stack direction="horizontal" gap={4}>
                                <a href="https://www.youtube.com/@hallilabs1083" className="youtube social" target="_blank" >
                                    <FaYoutube size="30px" />
                                </a>
                                <a href="https://www.twitter.com/@halliLabsOrg" className="twitter social" target="_blank">
                                    <FaTwitter size="30px" />
                                </a>
                            </Stack>
                            <br /><p>Registered under Society Act 1960 ( Under regulation no 17),
                                Government of Karnataka.
                                <p>
                                    <b>Registration No : </b>  DRB3/SOR/177/2018-2019<br />
                                </p>
                                <Button onClick={handleShow}>Registration Certificate</Button>
                                <Modal show={show} onHide={handleClose} animation={false}>
                                    <Modal.Header closeButton>
                                        <Modal.Title><b>Registration Certificate</b></Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="justify-content-center">
                                        <Image src={images.cert} className="reg-img" />
                                    </Modal.Body>
                                    <Modal.Footer>
                                    </Modal.Footer>
                                </Modal>
                            </p>
                        </div>
                    </Stack>
                </footer>
            </div>
        </>
    )
};
export default Footer;
