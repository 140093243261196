import React from "react";
import "./common.css";
import {
  Modal
} from "react-bootstrap";
import { useState } from 'react';

const Card = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div Class="col-md-4 col-10 mx-auto">
        <div Class="card">
          <div class="container">
            <img src={props.imgsrc} Class="card-img-top" alt={props.imgsrc} onClick={handleShow} />
            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton />
              <Modal.Body className="justify-content-center">
                <img src={props.imgsrc} Class="card-img-top" alt={props.imgsrc} />
                <Modal.Title className="title3">{props.title}</Modal.Title>
                <p Class="data-text">{props.data}</p>
              </Modal.Body>
              <Modal.Footer>
              </Modal.Footer>
            </Modal>
            <div class="middle">
              <button onClick={handleShow} class="text">Read More</button>
            </div>
          </div>
          <div Class="card-body">
            <h5 Class="title">{props.title}</h5>
            <h6 Class="title2">{props.designation}</h6>
            <p Class="card-text">{props.dept} <br /> {props.address} </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
